/* eslint-disable max-len */
import React from 'react'
import T from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Hero from '../components/Hero'
import Section from '../components/Section'
import ImageList from '../components/ImageList'
import Propaganda from '../components/Propaganda'
import Courses from '../components/Courses'
// Recipes video
import coursePoster from '../assets/videoes/course.jpg'
import courseWEBM from '../assets/videoes/course.webm'
import courseMP4 from '../assets/videoes/course.mp4'

export default function Page({
  data: {
    matpakkebrod,
    vannbakkels,
    hvetebakst,
    focaccia,
    tomatsauser,
    smorsauser,
    meljevninger,
    espen,
    jonas,
    kjell,
    heidi,
    jan,
    kristoffer,
    products,
  },
}) {
  const COURSES = [
    {
      id: 'matpakkebrod',
      title: 'Matpakkebrød',
      description:
        'Lag smakfullt og næringsrikt brød, som familien kommer til å elske! Lær deg triksene for saftige brød og gylne rundstykker.',
      ...matpakkebrod,
      instructor: {
        name: 'Espen Vesterdal Larsen',
        title: 'Kulinarisk Akademi',
        image: espen.image,
      },
    },
    {
      id: 'vannbakkels',
      title: 'Vannbakkels',
      description:
        'En klassiker man finner igjen i mange land. Enkle ingredienser, vanskelig å mestre. Lær triksene som gjør at du lykkes.',
      ...vannbakkels,
      instructor: {
        name: 'Kjell Patrick Ørmen Johansen',
        title: 'Kulinarisk Akademi',
        image: kjell.image,
      },
    },
    {
      id: 'hvetebakst',
      title: 'Hvetebakst',
      description:
        'Lær deg triksene for luftig hvetebakst! Mestre grunndeigen, og lag store boller og saftige kanelknuter. ',
      ...hvetebakst,
      instructor: {
        name: 'Espen Vesterdal Larsen',
        title: 'Kulinarisk Akademi',
        image: espen.image,
      },
    },
    {
      id: 'focaccia',
      title: 'Focaccia',
      description:
        'God Foccacia er en kunst! Få triksene bak en luftig focaccia med sprø skorpe.',
      ...focaccia,
      instructor: {
        name: 'Espen Vesterdal Larsen',
        title: 'Kulinarisk Akademi',
        image: espen.image,
      },
    },
    {
      id: 'tomatsauser',
      title: 'Tomatsauser',
      description:
        'Sunne friske sauser til fest og hverdags! Lær deg klassisk kokt tomatsaus og én tomatsalsa, som vi varierer på tre måter.',
      ...tomatsauser,
      instructor: {
        name: 'Jonas Modell',
        title: 'Kulinarisk Akademi',
        image: jonas.image,
      },
    },
    {
      id: 'smorsauser',
      title: 'Enkle smørsauser',
      description:
        'Smør kan brukes i saus på langt flere måter enn du tror. Lær deg smørsauser som er kjappe å lage og lette å tilpasse ditt bruk.',
      ...smorsauser,
      instructor: {
        name: 'Jonas Modell',
        title: 'Kulinarisk Akademi',
        image: jonas.image,
      },
    },
    {
      id: 'meljevninger',
      title: 'Meljevninger',
      description:
        'Grunnkurs i saus: Lær å bruke meljevninger og å lage grunnsausene hvit og brun saus. ',
      ...meljevninger,
      instructor: {
        name: 'Jonas Modell',
        title: 'Kulinarisk Akademi',
        image: jonas.image,
      },
    },
  ]

  return (
    <>
      <SEO title="Korte videokurs med kokker som lærer bort grunnleggende prinsipper og triks" />
      <Hero
        title="Korte videokurs med kokker som lærer bort grunnleggende prinsipper og triks"
        video={{
          poster: coursePoster,
          webm: courseWEBM,
          mp4: courseMP4,
        }}
        type="small"
        action={{ label: 'Last ned appen', to: '/last-ned' }}
      />
      <Section title="Møt våre kursholdere">
        <ImageList
          type="avatars"
          items={[
            {
              id: '1',
              title: (
                <>
                  <div>Espen Vesterdal Larsen</div>
                  Kokk og konditor, Kulinarisk Akademi / Foodsteps
                </>
              ),
              ...espen,
            },
            {
              id: '2',
              title: (
                <>
                  <div>Jonas Modell</div>
                  Kokk, Foodsteps
                </>
              ),
              ...jonas,
            },
            {
              id: '3',
              title: (
                <>
                  <div>Kjell Patrick Ørmen Johansen</div>
                  Kokk, Kulinarisk Akademi
                </>
              ),
              ...kjell,
            },
            {
              id: '4',
              title: (
                <>
                  <div>Heidi Iren Hansen</div>
                  Master of Wine, Vinansvarlig, Kulinarisk Akademi
                </>
              ),
              ...heidi,
            },
            {
              id: '5',
              title: (
                <>
                  <div>Kristoffer Westvik Lofnes</div>
                  Kokk, Kulinarisk Akademi
                </>
              ),
              ...kristoffer,
            },
            {
              id: '6',
              title: (
                <>
                  <div>Jan-Erik Hauge</div>
                  Kokk
                </>
              ),
              ...jan,
            },
          ]}
        />
      </Section>
      <br />
      <Section title="Noen av våre kurs" />
      <Courses items={COURSES} />
      <Propaganda products={products.products} />
    </>
  )
}

Page.propTypes = {
  location: T.object.isRequired,
  data: T.object.isRequired,
}

export const query = graphql`
  query {
    matpakkebrod: file(relativePath: { eq: "matpakkebrod.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vannbakkels: file(relativePath: { eq: "vannbakkels.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hvetebakst: file(relativePath: { eq: "hvetebakst.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    focaccia: file(relativePath: { eq: "focaccia.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tomatsauser: file(relativePath: { eq: "tomatsauser.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smorsauser: file(relativePath: { eq: "smorsauser.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    meljevninger: file(relativePath: { eq: "meljevninger.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    espen: file(relativePath: { eq: "avatars/espen.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jonas: file(relativePath: { eq: "avatars/jonas.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kjell: file(relativePath: { eq: "avatars/kjell-p.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heidi: file(relativePath: { eq: "avatars/heidi.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jan: file(relativePath: { eq: "avatars/jan-e.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kristoffer: file(relativePath: { eq: "avatars/kristoffer.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    products: foodsteps {
      products: products {
        id
        title
        description
        includes
        variants {
          promotion {
            price
            title
            id
            description
            eligible
          }
          price
          months
        }
      }
    }
  }
`
