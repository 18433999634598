import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Img from 'gatsby-image'

const bem = new BEMHelper('image-list')

export default function ImageList({ items, title, type }) {
  const Name = title ? 'h4' : 'h3'

  return (
    <div {...bem('', type)}>
      {title && <h3 {...bem('title')}>{title}</h3>}

      <ul {...bem('list')}>
        {items &&
          items.map(({ title, id, image, url }) => (
            <li key={id} {...bem('item')}>
              {image && (
                <Img fluid={{ ...image.fluid }} {...bem('image')} alt="" />
              )}
              {url && <img src={url} {...bem('image')} alt="" />}
              {title && (
                <div {...bem('content')}>
                  <Name {...bem('name')}>{title}</Name>
                </div>
              )}
            </li>
          ))}
      </ul>
    </div>
  )
}

ImageList.propTypes = {
  title: T.string,
  items: T.array.isRequired,
  type: T.string,
}
