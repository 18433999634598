import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Img from 'gatsby-image'

import { Container } from '@components'

const bem = new BEMHelper('courses')

export default function Courses({ items }) {
  return (
    <Container node="ul" {...bem('')}>
      {items &&
        items.map(({ title, id, description, image }) => (
          <li key={id} {...bem('item')}>
            {image && (
              <Img fluid={{ ...image.fluid }} {...bem('image')} alt={title} />
            )}
            <div {...bem('content')}>
              <h2 {...bem('title')}>{title}</h2>
              <p {...bem('description')}>{description}</p>
            </div>
          </li>
        ))}
    </Container>
  )
}

Courses.propTypes = {
  items: T.array.isRequired,
}
